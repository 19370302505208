import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.siteData;
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead">
            Please feel free to contact me if you have any questions about my work or want a consult for a custom piece.
            </p>
            <a href="mailto:kc@kcthejeweler.com">kc@kcthejeweler.com</a>
          </div>
        </div>
      </section>
    );
  }
}