let siteData = {
    "imagebaseurl":"https://kcthejeweler.com/",
    "name": "KC the Jeweler",
    "role": "Jewelry Designer & Metalsmith",
    "roleDescription": "Creating beatiful pieces of functional accessories from raw materials.",
    "socialLinks":[
        {
          "name":"twitter",
          "url":"http://twitter.com/kcthejeweler",
          "className":"fa fa-twitter"
        },
        {
          "name":"instagram",
          "url":"https://instagram.com/kcthejeweler",
          "className":"fa fa-instagram"
        },
        {
          "name":"youtube",
          "url":"https://www.youtube.com/channel/UC1cRSRDgVwZqWp6i7eOFJ9w",
          "className":"fa fa-youtube"
        }
    ],
    "website":"https://kcthejeweler.com",
    "about":[
      {
        "title":"About Me",
        "content":"Jewel craft for me started as a hobby, a distraction from work, but the call to it came to me at a very early age. My elders always had an admiration for the rare, the delicate, and the allure of precious stones and metals. Since my jewelry journey began I find myself thinking about it, designing more, and doing more with this hobby than I ever imagined. If you are looking for that accent accessory or custom piece for that special occasion, please let me design, create, and deliver that special piece for you. All my pieces are made by hand and designed by myself and my creative process. Whether you are interested in a staple set piece or a one-off custom order, all of my pieces are made to order for the customer and their occasion."
      },
      {
        "title":"Hand Made",
        "content":"Every piece of jewelry that leaves my shop is 100% handmade. Each ring is carefully formed, every prong is meticulously polished, each engraving is drawn by hand, and it is my pleasure to bestow the wearer of these items with the artisinal effort involved in its' creation.",
      },
      {
        "title":"Reclaimed Metals",
        "content":"When possible or requested (reclaim item needed in custom order) my metals are sourced and tested locally. Whether it be pawn, estate, yard, or garage sale, the majority of my pieces begin their life as unwanted scrap. From this scrap I melt the desired metals into a molten ball and pour the liquid into its' initial shape - be it a bar, bead, or flat sheet."
      }
    ],
    "portfolio":[
      {
        "name":"Moth Pendant",
        "description":"Simple and elegant moth with beautiful cabochon.",
        "imgurl":"images/portfolio/pendant_moth.jpg",
        "storeurl":"none"
      },
      {
        "name":"Cabochon Cross",
        "description":"A heat treated cabochon set in the center of a crucifix.",
        "imgurl":"images/portfolio/pendant_cross.jpg",
        "storeurl":"none"
      },
      {
        "name":"Royal Rings",
        "description":"The King and Queen commitment rings.",
        "imgurl":"images/portfolio/ring_royals.jpg",
        "storeurl":"none"
      },
      {
        "name":"Stackers",
        "description":"Classic stacker rings, available in many textures, finishes, forms, and sets.",
        "imgurl":"images/portfolio/ring_stacks.jpg",
        "storeurl":"none"
      },
      {
        "name":"Scarf Pins",
        "description":"Scarf or broach pins, for the season or occassion.",  
        "imgurl":"images/portfolio/scarf_pin.jpg",
        "storeurl":"none"
      },
      {
        "name":"Skull & Dagger Pin",
        "description":"This particular scarf pin is a skull and dagger, many shapes and sizes are possible.",  
        "imgurl":"images/portfolio/scarf_pin_skull_dagger.jpg",
        "storeurl":"none"
      },
      {
        "name":"Rough Elegance",
        "description":"Elegant yet simple and dynamic hammer finish textured rings.",
        "imgurl":"images/portfolio/ring_texture.jpg",
        "storeurl":"none"
      },
      {
        "name":"Omega Broach",
        "description":"This broach or scarf pin is a classic design with a gentle patina.",  
        "imgurl":"images/portfolio/scarf_pin_copper.jpg",
        "storeurl":"none"
      },
      {
        "name":"Floating Solitaire",
        "description":"This stone is set in a floating ring, providing maximum view of the pavillion.",  
        "imgurl":"images/portfolio/ring_setting.jpg",
        "storeurl":"none"
      }
    ],
    "testimonials":[
      {
        "description":"Wow Dad, I really really love my pretty new ring!",
        "name":"Daughter"
      },
      {
        "description":"Nice work honey, you are really improving and getting better at this.",
        "name":"Wife"
      }
    ]
  }
  
  export default siteData