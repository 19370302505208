import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let siteData = this.props.siteData;
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

            </div>

            <div className="nine columns main-col">
               {
                  siteData.about && siteData.about.map(item =>{
                     return(
                           <>
                              <h2>{item.title}</h2>
                              <p>{item.content}</p>
                           </>
                           )
                        }
                  )
               }
            </div>
         </div>
      </section>
    );
  }
}